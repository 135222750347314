var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"role":"form"}},[_c('message',{staticClass:"m-3",attrs:{"messageKey":"messaggio-form"}}),(_vm.loginRequired)?_c('div',{staticClass:"mx-auto m-5 p-5 d-flex flex-column justify-content-center align-items-center"},[_c('h5',[_vm._v(_vm._s(_vm.$t("login.obbligatorio")))]),_c('router-link',{attrs:{"to":{ name: 'Login' }}},[_c('e-button',{staticClass:"btn-lg w-100 mt-5"},[_vm._v(_vm._s(_vm.$t("login.accedi")))])],1)],1):_c('keep-alive',[_c('div',{staticClass:"mx-auto form-padding",attrs:{"data-tour-step":"10"}},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":"","width":"100vw"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../../assets/easyload-xl.webm"),"type":"video/webm"}})])]):(_vm.form)?_c('div',[(
                        _vm.configs.login_pubblico.stato == 'facoltativo' &&
                            !_vm.user
                    )?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center my-3"},[_c('e-button',{staticClass:"w-100 m-2 mb-3",on:{"click":_vm.login}},[_vm._v(_vm._s(_vm.$t("login.accedi")))]),_c('p',{staticStyle:{"color":"#666"}},[_vm._v(_vm._s(_vm.$t("login.else")))])],1):_vm._e(),(_vm.showErrors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t("form.field_error"))+" ")]):_vm._e(),(_vm.blocked && _vm.blocked.length > 0)?[(_vm.number_of_reservations > 1)?_c('h3',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("form.dati_generici"))+" ")]):_vm._e(),_vm._l((_vm.blocked),function(input){return _c('easy-input',{key:input.id,ref:'blocked_input',refInFor:true,attrs:{"disabled":_vm.configs.login_pubblico.auth_blocked != 0 &&
                                _vm.configs.login_pubblico.auth_blocked !=
                                    '0' &&
                                _vm.user &&
                                _vm.user[input.valore_auth] != null,"valore-auth":input.valore_auth,"input":input,"showErrors":_vm.showErrors,"touch":_vm.touch,"codice":input.codice}})})]:_vm._e(),_vm._l((_vm.form),function(f,i){return _c('div',{key:i,staticClass:"mb-3",style:(_vm.form.lenght > 0
                            ? 'padding : 1rem; border : 1px solid #ccc; border-radius: 20px'
                            : null)},[(_vm.number_of_reservations > 1)?_c('h5',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("form.n_prenotazione", { n: i + 1 }))+" ")]):_vm._e(),(f.datiUtente)?_c('div',_vm._l((f.datiUtente),function(input){return _c('easy-input',{key:input.id,ref:'input_' + i,refInFor:true,attrs:{"disabled":_vm.configs.login_pubblico.auth_blocked != 0 &&
                                    _vm.configs.login_pubblico.auth_blocked !=
                                        '0' &&
                                    _vm.user &&
                                    _vm.user[input.valore_auth] != null,"id":i +'_' +  input.id,"valore-auth":input.valore_auth,"input":input,"showErrors":_vm.showErrors,"touch":_vm.touch,"codice":input.codice}})}),1):_vm._e(),(f.datiServizio)?_c('div',{staticClass:"mb-3"},_vm._l((f.datiServizio),function(input){return _c('easy-input',{key:input.id,ref:'input_' + i,refInFor:true,attrs:{"disabled":_vm.configs.login_pubblico.auth_blocked != 0 &&
                                    _vm.configs.login_pubblico.auth_blocked !=
                                        '0' &&
                                    _vm.user &&
                                    _vm.user[input.valore_auth] != null,"id":i +'_' +  input.id,"valore-auth":input.valore_auth,"input":input,"showErrors":_vm.showErrors,"touch":_vm.touch,"codice":input.codice}})}),1):_vm._e(),(f.altriDati)?_c('div',{staticClass:"mb-3"},_vm._l((f.altriDati),function(input){return _c('easy-input',{key:input.id,ref:'input_' + i,refInFor:true,attrs:{"disabled":_vm.configs.login_pubblico.auth_blocked != 0 &&
                                    _vm.configs.login_pubblico.auth_blocked !=
                                        '0' &&
                                    _vm.user &&
                                    _vm.user[input.valore_auth] != null,"id":i +'_' +  input.id,"valore-auth":input.valore_auth,"codice":input.codice,"input":input,"showErrors":_vm.showErrors,"touch":_vm.touch}})}),1):_vm._e()])}),_c('p',{staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(_vm.$t("form.obbligatorio")))])]),_c('router-link',{staticClass:"btn btn-primary btn-lg w-100 mt-5 text-uppercase",style:({
                        backgroundColor:
                            _vm.$store.state.Ente.colors &&
                            _vm.$route.name != 'Home'
                                ? _vm.$store.state.Ente.colors.sfondo[0]
                                : ''
                    }),attrs:{"to":_vm.to}},[_vm._v(_vm._s(_vm.$t("buttons.next")))])],2):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }