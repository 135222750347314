<template>
    <div role="form">
        <message messageKey="messaggio-form" class="m-3" />
        <div
            v-if="loginRequired"
            class="mx-auto m-5 p-5 d-flex flex-column justify-content-center align-items-center"
        >
            <h5>{{ $t("login.obbligatorio") }}</h5>
            <router-link :to="{ name: 'Login' }"
                ><e-button class="btn-lg w-100 mt-5">{{
                    $t("login.accedi")
                }}</e-button></router-link
            >
        </div>
        <keep-alive v-else>
            <div class="mx-auto form-padding" data-tour-step="10">
                <div v-if="loading" class="loading">
                    <video autoplay loop muted playsinline width="100vw">
                        <source
                            src="../../assets/easyload-xl.webm"
                            type="video/webm"
                        />
                    </video>
                </div>
                <div v-else-if="form">
                    <div
                        v-if="
                            configs.login_pubblico.stato == 'facoltativo' &&
                                !user
                        "
                        class="d-flex flex-column justify-content-center align-items-center my-3"
                    >
                        <e-button class="w-100 m-2 mb-3" @click="login">{{
                            $t("login.accedi")
                        }}</e-button>

                        <p style="color: #666">{{ $t("login.else") }}</p>
                    </div>
                    <div
                        v-if="showErrors"
                        class="alert alert-danger"
                        role="alert"
                    >
                        {{ $t("form.field_error") }}
                    </div>
                    <template v-if="blocked && blocked.length > 0">
                        <h3
                            v-if="number_of_reservations > 1"
                            class="text-center"
                        >
                            {{ $t("form.dati_generici") }}
                        </h3>
                        <easy-input
                            :disabled="
                                configs.login_pubblico.auth_blocked != 0 &&
                                    configs.login_pubblico.auth_blocked !=
                                        '0' &&
                                    user &&
                                    user[input.valore_auth] != null
                            "
                            v-for="input in blocked"
                            :key="input.id"
                            :ref="'blocked_input'"
                            :valore-auth="input.valore_auth"
                            :input="input"
                            :showErrors="showErrors"
                            :touch="touch"
                            :codice="input.codice"
                        />
                    </template>
                    <div
                        v-for="(f, i) in form"
                        :key="i"
                        class="mb-3"
                        :style="
                            form.lenght > 0
                                ? 'padding : 1rem; border : 1px solid #ccc; border-radius: 20px'
                                : null
                        "
                    >
                        <h5
                            v-if="number_of_reservations > 1"
                            class="text-center"
                        >
                            {{ $t("form.n_prenotazione", { n: i + 1 }) }}
                        </h5>
                        <div v-if="f.datiUtente">
                            <easy-input
                                :disabled="
                                    configs.login_pubblico.auth_blocked != 0 &&
                                        configs.login_pubblico.auth_blocked !=
                                            '0' &&
                                        user &&
                                        user[input.valore_auth] != null
                                "
                                v-for="input in f.datiUtente"
                                :id="i +'_' +  input.id"
                                :key="input.id"
                                :ref="'input_' + i"
                                :valore-auth="input.valore_auth"
                                :input="input"
                                :showErrors="showErrors"
                                :touch="touch"
                                :codice="input.codice"
                            />
                        </div>
                        <div v-if="f.datiServizio" class="mb-3">
                            <easy-input
                                :disabled="
                                    configs.login_pubblico.auth_blocked != 0 &&
                                        configs.login_pubblico.auth_blocked !=
                                            '0' &&
                                        user &&
                                        user[input.valore_auth] != null
                                "
                                v-for="input in f.datiServizio"
                                :key="input.id"
                                :id="i +'_' +  input.id"
                                :ref="'input_' + i"
                                :valore-auth="input.valore_auth"
                                :input="input"
                                :showErrors="showErrors"
                                :touch="touch"
                                :codice="input.codice"
                            />
                        </div>
                        <div v-if="f.altriDati" class="mb-3">
                            <easy-input
                                :disabled="
                                    configs.login_pubblico.auth_blocked != 0 &&
                                        configs.login_pubblico.auth_blocked !=
                                            '0' &&
                                        user &&
                                        user[input.valore_auth] != null
                                "
                                v-for="input in f.altriDati"
                                :key="input.id"
                                :ref="'input_' + i"
                                :id="i +'_' +  input.id"
                                :valore-auth="input.valore_auth"
                                :codice="input.codice"
                                :input="input"
                                :showErrors="showErrors"
                                :touch="touch"
                            />
                        </div>

                    </div>
                    <p class="text-danger">
                        <small>{{ $t("form.obbligatorio") }}</small>
                    </p>
                    <router-link
                        :to="to"
                        class="btn btn-primary btn-lg w-100 mt-5 text-uppercase"
                        :style="{
                            backgroundColor:
                                $store.state.Ente.colors &&
                                $route.name != 'Home'
                                    ? $store.state.Ente.colors.sfondo[0]
                                    : ''
                        }"
                        >{{ $t("buttons.next") }}</router-link
                    >
                </div>
            </div>
        </keep-alive>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import EasyInput from "../inputs/EasyInput";
//@group Form
export default {
    name: "Form",

    components: {
        EasyInput
    },
    data() {
        return {
            model: null,
            next: false,
            showErrors: false,
            touch: false,
            loading: false,
            loginRequired: false,
            oldServizio: null,
            oldNumberOfReservations: null,
            blocked: [],
            deactive: false
        };
    },
    mounted() {
        /**
         * Mappa la sessione alle entry poi valorizza i campi con la loro validazione
         */
        this.mapSessionToState().then(() => this.setFormData());
    },
    watch: {
        servizio(value) {
            if (value == null) return;
            this.mapSessionToState().then(() => this.setFormData());
        },
        number_of_reservations() {
            this.deactive = true;
        }
    },
    /**
     * @vuese
     * Chiamata quando il componente è in modalità keep-alive
     */
    activated() {
        if (!this.servizio) throw new Error("Errore Form");
        if (this.servizio.id != this.oldServizio || this.deactive) {
            if (this.session) {
                this.showErrors = false;
                this.touch = false;
            }
            this.deactive = false;
            this.setFormData();
        }
    },
    deactivated() {
        this.oldServizio = this.servizio.id;
        this.oldNumberOfReservations = this.number_of_reservations;
    },
    computed: {
        ...mapState({
            servizio: state => state.Entry.servizio,
            formFields: state => state.Utils.form,
            form: state => state.Utils.formInputs,
            user: state => state.User.loggedJwt,
            configs: state => state.Ente.configs,
            session: state => state.Utils.session,
            entry: state => state.Entry,
            number_of_reservations: state => +state.Entry.numberOfReservations
        }),

        to() {
            return "Riepilogo";
        }
    },
    /**
     * @vuese
     * Prima di lasciare la pagina valida i campi
     * e blocca la navigazione se questa non è diretta verso una delle permittedRoutes[]
     */
    beforeRouteLeave(to, __, next) {
        const permittedRoutes = [
            this.$routes.HOMECLIENTE,
            this.$routes.HOME,
            this.$routes.LOGIN,
            this.$routes.PRENOTA.CALENDARIO,
            this.$routes.PRENOTA.SERVIZI,
            this.$routes.SERVIZI_BREVI.CALENDARIO,
            this.$routes.SERVIZI_BREVI.SERVIZI,
            this.$routes.GESTISCI
        ];

        // [
        //   "servizi",
        //   "Gestisci",
        // ];

        if (permittedRoutes.indexOf(to.name) != -1) {
            if (to.name != "Login") {
                this.setData(null);
            }
            return next();
        }

        this.setTouch().then(() =>
            this.validate()
                .then(() => next())
                .catch(e => {
                    this.showErrors = true;
                    console.error(e);
                })
        );
    },

    methods: {
        ...mapMutations({
            setDatiUtente: "Entry/SET_DATI_UTENTE",
            setForm: "Utils/SET_FORM",
            toogleFormLoading: "Utils/TOOGLE_FORM_LOADING",
            setData: "Entry/SET_DATE",
            setFormInputs: "Utils/SET_FORMINPUTS",
            setEntry: "Entry/SET_ENTRY"
        }),
        setFormData() {
            this.getFormElements().then(() => {
                for (const field in this.user) {
                    let ref_name = "";
                    if (this.number_of_reservations === 1) ref_name = "input_0";
                    else ref_name = "blocked_input";
                    this.$refs[ref_name].map(input => {
                        if (input.valoreAuth === field) {
                            input.$v[input.codice].$model = this.user[field];
                        }
                    });
                }
            });
        },
        /**
         * @vuese
         * Se dopo il login è presente una sessione con un entry la rimappa allo state di vuex
         */
        mapSessionToState() {
            this.showErrors = false;
            return new Promise(resolve => {
                if (this.session) {
                    this.setEntry(this.session.Entry);
                    this.setFormInputs(this.session.Utils.formInputs);
                    this.setForm(this.session.Utils.form);
                }
                resolve();
            });
        },
        /**
         * @vuese
         * Chiama api per ritirare i campi del form
         */
        getFormElements() {
            if (
                !this.servizio ||
                !Object.prototype.hasOwnProperty.call(this.servizio, "id")
            ) {
                this.loading = false;
                return new Promise(resolve => {
                    resolve();
                });
            }
            return new Promise((resolve, reject) => {
                this.loading = true;
                this.$http
                    .get(
                        `/api/cliente/${this.cliente.Codice}/formData/${this.servizio.id}`
                    )
                    .then(res => {
                        if (res.data == "login") {
                            this.loginRequired = true;
                            return;
                        }
                        const formFields = res.data;

                        this.setForm(formFields);
                        let form = [];

                        this.blocked = formFields.filter(e => e.blocked == 1);
                        const datiUtente = formFields.filter(
                            e => e.posizione == 1 && e.blocked != 1
                        );
                        const datiServizio = formFields.filter(
                            e => e.posizione == 2 && e.blocked != 1
                        );
                        const altriDati = formFields.filter(
                            e =>
                                e.posizione != 1 &&
                                e.posizione != 2 &&
                                e.blocked != 1
                        );

                        for (let i = 0; i < this.number_of_reservations; i++) {
                            let f = {};
                            f.datiUtente = datiUtente;
                            f.datiServizio = datiServizio;
                            f.altriDati = altriDati;
                            form[i] = f;
                        }

                        this.setFormInputs(form);

                        this.loading = false;
                        resolve();
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },
        /**
         * @vuese
         * setta il dato touch a true
         */
        setTouch() {
            return new Promise(resolve => {
                this.touch = true;
                resolve();
            });
        },
        /**
         * @vuese
         * valida i campi e mostra errori se necessario
         */
        validate() {
            return new Promise((resolve, reject) => {
                let fields_array = [];
                for (let i = 0; i < this.number_of_reservations; i++) {
                    const isIncorrect = this.$refs["input_" + i].filter(
                        e => e.$v.$anyError
                    );

                    if (isIncorrect.length > 0) {
                        let firstWrongInput = isIncorrect[0].$el.querySelector('input');

                        firstWrongInput.focus();
                        return reject("Form fields " + i);
                    }

                    let fields = this.$refs["input_" + i].map(input => {
                        return this.formFields
                            .map(el => {
                                if (input.$v[el.codice]) {
                                    return {
                                        [el.codice]: input.$v[el.codice].$model
                                    };
                                }
                            })
                            .filter(function(el) {
                                return el != null;
                            })[0];
                    });
                    fields_array.push(fields);
                }

                if (!this.$refs["blocked_input"]) {
                    fields_array = fields_array.map(form => {
                        return [...form];
                    });

                    this.setDatiUtente(fields_array);

                    return resolve();
                }
                const isIncorrect = this.$refs["blocked_input"].filter(
                    e => e.$v.$anyError
                );

                if (isIncorrect.length > 0) {
                    return reject("FORM: valore incorretto in blocked");
                }

                const blocked = this.$refs["blocked_input"].map(input => {
                    return this.formFields
                        .map(el => {
                            if (input.$v[el.codice]) {
                                return {
                                    [el.codice]: input.$v[el.codice].$model
                                };
                            }
                        })
                        .filter(function(el) {
                            return el != null;
                        })[0];
                });
                fields_array = fields_array.map(form => {
                    return [...form, ...blocked];
                });

                this.setDatiUtente(fields_array);

                return resolve();
            });
        },
        login() {
            localStorage.setItem("redirect", this.$route.name);
            this.$router.push({ name: this.$routes.LOGIN });
        }
    }
};
</script>

<style>
.form {
    min-height: 500px;
}
</style>
